import { DispatcherComponent } from '../dispatcher/containers';
import { AuthentificationComponent } from '../auth/containers';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RemoteWorkGuard } from './remote-work.guard';
import { CabinetBalanceComponent, CabinetComponent, CabinetMainComponent } from '../cabinet/containers';
import { IsLoginGuard } from './is-login.guard';


const routes: Routes = [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DispatcherComponent,
      canActivate: [AuthGuard, RemoteWorkGuard]
    },
    {
      path: 'cabinet',
      component: CabinetComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: '',
          component: CabinetMainComponent,
          pathMatch: 'full',
        },
        {
          path: 'balance',
          component: CabinetBalanceComponent,
          pathMatch: 'full',
        },
      ],
    },
    {
      path: 'login',
      canActivate: [IsLoginGuard],
      component: AuthentificationComponent
    },
    {
    path: '**',
    redirectTo: 'dashboard'
    }
  ]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
