import {ImageInterface} from '../interfaces/image.interface';

export const DRIVER_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'driver_avatar', url: ''},
  {type: 'driver_license_front', url: ''},
  {type: 'driver_license_rear', url: ''},
];

export const VEHICLE_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'vehicle_registration_front', url: ''},
  {type: 'vehicle_registration_rear', url: ''},
  {type: 'vehicle_front', url: ''},
  {type: 'vehicle_rear', url: ''},
  {type: 'vehicle_left_side', url: ''},
  {type: 'vehicle_right_side', url: ''},
  {type: 'vehicle_interior_front', url: ''},
  {type: 'vehicle_interior_rear', url: ''},
];
